import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"
import Badge from "../components/badge"
import { SliceMindMeta } from "../data/seo-meta"

import starIcon from "../images/common/star-icon.svg"
import checkIcon from "../images/common/check_icon.svg"

import manLaptop from "../images/slice-mind/man-laptop.svg"
import womanBalloon from "../images/slice-mind/woman-ballon.svg"
import platformGraph from "../images/slice-mind/platform-graph.png"
import stuart from "../images/slice-mind/stuart.png"

const crucialAreaBadges = [
  "Risk Assessment",
  "Underwriting",
  "Claims Protection",
  "Automated Document Handling",
  "Fraud Detection",
  "Compliance & Regulatory Assistance",
  "Personalized Recommendations",
]

const platformProvides = [
  {
    title: "Customer experience:",
    content: "Meticulously crafted user experience.",
  },
  {
    title: "End-to-end value chain capabilities:",
    content:
      "Simplified processes for automated underwriting, claims, and settlement.",
  },
  {
    title: "AI & ML:",
    content: "Embedded technologies for enhanced functionality.",
  },
  {
    title: "Signals & events:",
    content: "Derived insights for personalized experiences.",
  },
  {
    title: "Regulatory, compliance & reporting:",
    content: "Streamlined management on one platform.",
  },
]

const platformBenefits = [
  {
    title: "Agent Benefits",
    contents: [
      "Accelerated and precise quoting, empowering agents to secure more business and foster greater customer retention.",
      "Empowering agents with a robust dashboard for streamlined operations.",
      "Tailored product offerings featuring customizable coverage options derived from diverse data sources.",
    ],
  },
  {
    title: "Distribution Partner (MGAs / Wholesalers) Benefits",
    contents: [
      "Harnessing data-driven insights for informed pricing and product development, fostering a deeper understanding of the market, agents, and customers.",
      "A robust partner dashboard showcasing our best-in-class AI/ML/LLM capabilities, granting a competitive edge to infiltrate the market.",
      "Effortless integration with existing systems, ensuring seamless scalability as your business expands.",
    ],
  },
  {
    title: "Carrier Benefits",
    contents: [
      "Enhanced underwriting accuracy through Slice Mind’s co-pilot underwriter, providing precise information with a reduction of noise and biases.",
      "Advanced fraud detection and risk mitigation capabilities, enabling early detection to minimize risks and losses for both carriers and insured small businesses.",
    ],
  },
]

const SliceMindPage = () => {
  return (
    <Layout>
      <SEO
        description={SliceMindMeta.description}
        path={SliceMindMeta.path}
        title={SliceMindMeta.title}
      />

      <div className="container py-20 md:py-24">
        <div className="grid md:grid-cols-2 gap-20 mb-10">
          <div className="md:pr-24">
            <h1 className="h1 mb-8">Slice Mind</h1>
            <p className="p1">
              Built with AI, ML, behavioral science, and Large Language Models,
              Slice Mind and our Insurance Cloud Services (ICS) platform are the
              core of everything we do and build. This innovation makes our
              products different, and, we’d argue, better.
              <br />
              <br />
              Brilliantly designed for agents, clients, and carriers alike, each
              product crafted on ICS offers swift access to fully digital
              insurance solutions.
            </p>
          </div>
          <img src={manLaptop} alt="Man using a laptop" />
        </div>

        <h2 className="h2 mb-8">Here’s how:</h2>
        <p className="p1 mb-8">
          Since day one, we’ve pushed the boundaries of AI and ML, striving to
          craft smarter, superior products. Slice Mind and the ICS Platform have
          been utilized in products for:
        </p>
        <div className="grid gap-8 mb-24 md:grid-cols-5">
          {platformProvides.map((topic) => (
            <div key={topic.title}>
              <div className="px-7 py-8 rounded-2xl bg-white drop-shadow">
                <img className="mb-4" src={starIcon} alt="Star icon" />
                <h3 className="text-[2rem] leading-[2.4rem] font-semibold">
                  {topic.title}
                </h3>
                <p className="text-[1.6rem] leading-[2.4rem]">
                  {topic.content}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-wrap mb-10">
          <div className="basis-full md:basis-1/2">
            <h2 className="h2 mb-8">Elevate the insurance experience.</h2>
            <p className="p1 mb-8">
              We continually sought greater efficiency for our products, testing
              and implementing innovations as we learned. Today, leveraging
              insights and experiences, we enhance our current offerings. These
              improvements are seamlessly integrated into every facet of our
              platform and products, delivering enhanced capabilities for both
              partners and end users, and who’s kidding who, for us too!
            </p>
            <p className="p1 mb-8">
              Large Language Models (LLMs) have revolutionized our capabilities,
              amplifying our potential. Assisting in crucial areas like:
            </p>
            <div className="flex flex-wrap mb-8">
              {crucialAreaBadges.map((badge) => (
                <Badge label={badge} className="mr-6 my-3" key={badge} />
              ))}
            </div>
          </div>
          <div className="basis-full md:basis-1/2">
            <img src={womanBalloon} alt="Woman holding a balloon" />
          </div>
        </div>

        <h2 className="h3">
          Best-in-Class AI Technology with the Leading End-to-End Specialty
          Platform
        </h2>
        <img
          className="mx-auto"
          src={platformGraph}
          alt="Slice platform graph"
        />
        <p className="p1 mb-4">
          These models efficiently process the vast information we provide,
          enabling faster search and retrieval of answers,{" "}
          <span className="italic">which is a good thing!</span> Slice Mind
          gives us deeper insights, fed directly to the ICS platform, so we can
          elevate the insurance experience for both – our partners and end-users
          in tandem.
        </p>
        <div className="mb-16">
          <CallToAction label="Contact us" to="/contact/" />
        </div>

        <h2 className="h2 mb-12">
          Explore tailored benefits across the board.
        </h2>
        <div className="grid gap-8 mb-28 md:grid-cols-3">
          {platformBenefits.map((res) => (
            <div
              className="px-12 py-16 rounded-2xl bg-[#f8f8f8] drop-shadow"
              key={res.title}
            >
              <img src={checkIcon} alt={res.title} />
              <h3 className="p1 pt-8 mb-2 font-semibold">{res.title}</h3>
              {res.contents.map((content) => (
                <p className="p2 mb-4" key={content}>
                  {content}
                </p>
              ))}
            </div>
          ))}
        </div>

        <div className="flex flex-wrap mb-28 md:px-12 md:flex-nowrap">
          <div className="md:px-20">
            <img
              className="mb-8 min-w-[12rem]"
              src={stuart}
              alt="Stuart Baserman, CTO at Slice"
            />
          </div>
          <div>
            <p className="h3 italic font-semibold mb-8">
              Eight years in this space gives us an edge. We have a good
              understanding of the problems and we’re good at finding the right
              solutions. We have the data, the tech and the people in place.
              That’s what sets us apart.
            </p>
            <p className="h3 italic">– Stuart Baserman, CTO at Slice</p>
          </div>
        </div>

        <div className="md:text-center mb-20">
          <h2 className="h2 mx-auto">Transform your insurance approach.</h2>
          <p className="p1">
            Contact Slice to explore our innovative platform and products.
            <br />
            <br />
            <CallToAction label="Connect with us" to="/contact/" />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default SliceMindPage
