import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

const Badge = ({ label, className }) => (
  <span
    className={clsx(
      "p3 inline-block bg-[#f8f8f8] px-10 py-6 rounded-full badge-drop-shadow",
      className
    )}
  >
    {label}
  </span>
)

Badge.defaultProps = {
  className: "",
}

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Badge
